.landing_page {
  padding-top: 130px;
  max-width: 1600px;
  padding-left: 45px;
  padding-right: 45px;
  margin-left: auto;
  margin-right: auto;
}
.about_text {
  font-size: 15px;
  color: black;
  /* text-align: justify; */
}

.study_header {
  background-color: #BFBFBF;
  border-radius: 10px;
  padding: 15px;
  max-width: 100%;
}

.study {
  margin-left: 3%;
  margin-right: 3%;
}

.study-about {
  width: 600px;

}

.study_button {
  float: right;
  padding: 8px 13px;
  border-radius: 7px;
  border-width: 3px;
  background-color: white;
}

.study_summary_button {
  float: left;
  padding: 8px 13px;
  border-radius: 7px;
  border-width: 3px;
  background-color: white;
}

.study-summary-dropdown {
  font-size: 18px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;

  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;

  margin: 4px;

  width: 100%;
  background-color: #eeeeee;
  border: none;
  cursor: pointer;
}

:active, .study-summary-dropdown:hover {
  background-color: #bebebe;
}

.study-summary-ul {
  list-style-type: circle;
}

.study-summary-ol {
  list-style-type: decimal;
}

.row_l {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.img-responsive2 {
  display: block;
  max-width: 300px;
  height: auto;
}
@media screen and (max-width: 600px) {
  .landing_page {
    padding-top: 160px !important;
    padding-left: 15px;
    padding-right: 15px;
    /* max-width: 1800px; */
  }
  .study-about {
    width: 100%;
  }

  .study {
    margin: auto;
  }
}
@media screen and (max-width: 800px) {
  .landing_page {
    padding-top: 180px;
    padding-left: 15px;
    padding-right: 15px;
    /* max-width: 1800px; */
  }
}
@media screen and (min-width: 1400px) {
  .fs-4 {
    /* font-size: calc(1.3rem + 0.6vw) !important; */
    font-size: 20px !important;
  }
}
.m-sm-0 {
  margin: 0 !important;
}

.m-sm-1 {
  margin: 0.25rem !important;
}

.m-sm-2 {
  margin: 0.5rem !important;
}

.m-sm-3 {
  margin: 1rem !important;
}

.m-sm-4 {
  margin: 1.5rem !important;
}
.mt-lg-0 {
  margin-top: 0 !important;
}

.mt-lg-1 {
  margin-top: 0.25rem !important;
}

.mt-lg-2 {
  margin-top: 0.5rem !important;
}

.mt-lg-3 {
  margin-top: 1rem !important;
}

.mt-lg-4 {
  margin-top: 1.5rem !important;
}

.mt-lg-5 {
  margin-top: 3rem !important;
}

.mt-lg-auto {
  margin-top: auto !important;
}

.me-lg-0 {
  margin-right: 0 !important;
}

.me-lg-1 {
  margin-right: 0.25rem !important;
}

.me-lg-2 {
  margin-right: 0.5rem !important;
}

.me-lg-3 {
  margin-right: 1rem !important;
}

.me-lg-4 {
  margin-right: 1.5rem !important;
}

.me-lg-5 {
  margin-right: 3rem !important;
}

.me-lg-auto {
  margin-right: auto !important;
}

.mb-lg-0 {
  margin-bottom: 0 !important;
}

.mb-lg-1 {
  margin-bottom: 0.25rem !important;
}

.mb-lg-2 {
  margin-bottom: 0.5rem !important;
}

.mb-lg-3 {
  margin-bottom: 1rem !important;
}

.mb-lg-4 {
  margin-bottom: 1.5rem !important;
}

.mb-lg-5 {
  margin-bottom: 3rem !important;
}

.mb-lg-auto {
  margin-bottom: auto !important;
}

.ms-lg-0 {
  margin-left: 0 !important;
}

.ms-lg-1 {
  margin-left: 0.25rem !important;
}

.ms-lg-2 {
  margin-left: 0.5rem !important;
}

.ms-lg-3 {
  margin-left: 1rem !important;
}

.ms-lg-4 {
  margin-left: 1.5rem !important;
}

.ms-lg-5 {
  margin-left: 3rem !important;
}

.ms-lg-auto {
  margin-left: auto !important;
}
.m-sm-5 {
  margin: 3rem !important;
}

.m-sm-auto {
  margin: auto !important;
}

.mx-sm-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-sm-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-sm-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-sm-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-sm-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-sm-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-sm-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-sm-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-sm-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-sm-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-sm-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-sm-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-sm-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-sm-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-sm-0 {
  margin-top: 0 !important;
}

.mt-sm-1 {
  margin-top: 0.25rem !important;
}

.mt-sm-2 {
  margin-top: 0.5rem !important;
}

.mt-sm-3 {
  margin-top: 1rem !important;
}

.mt-sm-4 {
  margin-top: 1.5rem !important;
}

.mt-sm-5 {
  margin-top: 3rem !important;
}

.mt-sm-auto {
  margin-top: auto !important;
}

.me-sm-0 {
  margin-right: 0 !important;
}

.me-sm-1 {
  margin-right: 0.25rem !important;
}

.me-sm-2 {
  margin-right: 0.5rem !important;
}

.me-sm-3 {
  margin-right: 1rem !important;
}

.me-sm-4 {
  margin-right: 1.5rem !important;
}

.me-sm-5 {
  margin-right: 3rem !important;
}

.me-sm-auto {
  margin-right: auto !important;
}

.mb-sm-0 {
  margin-bottom: 0 !important;
}

.mb-sm-1 {
  margin-bottom: 0.25rem !important;
}

.mb-sm-2 {
  margin-bottom: 0.5rem !important;
}

.mb-sm-3 {
  margin-bottom: 1rem !important;
}

.mb-sm-4 {
  margin-bottom: 1.5rem !important;
}

.mb-sm-5 {
  margin-bottom: 3rem !important;
}

.mb-sm-auto {
  margin-bottom: auto !important;
}

.ms-sm-0 {
  margin-left: 0 !important;
}

.ms-sm-1 {
  margin-left: 0.25rem !important;
}

.ms-sm-2 {
  margin-left: 0.5rem !important;
}

.ms-sm-3 {
  margin-left: 1rem !important;
}

.ms-sm-4 {
  margin-left: 1.5rem !important;
}

.ms-sm-5 {
  margin-left: 3rem !important;
}

.ms-sm-auto {
  margin-left: auto !important;
}

.p-sm-0 {
  padding: 0 !important;
}

.p-sm-1 {
  padding: 0.25rem !important;
}

.p-sm-2 {
  padding: 0.5rem !important;
}

.p-sm-3 {
  padding: 1rem !important;
}

.p-sm-4 {
  padding: 1.5rem !important;
}

.p-sm-5 {
  padding: 3rem !important;
}

.px-sm-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-sm-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-sm-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-sm-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-sm-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-sm-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-sm-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-sm-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-sm-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-sm-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-sm-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-sm-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-sm-0 {
  padding-top: 0 !important;
}

.pt-sm-1 {
  padding-top: 0.25rem !important;
}

.pt-sm-2 {
  padding-top: 0.5rem !important;
}

.pt-sm-3 {
  padding-top: 1rem !important;
}

.pt-sm-4 {
  padding-top: 1.5rem !important;
}

.pt-sm-5 {
  padding-top: 3rem !important;
}

.pe-sm-0 {
  padding-right: 0 !important;
}

.pe-sm-1 {
  padding-right: 0.25rem !important;
}

.pe-sm-2 {
  padding-right: 0.5rem !important;
}

.pe-sm-3 {
  padding-right: 1rem !important;
}

.pe-sm-4 {
  padding-right: 1.5rem !important;
}

.pe-sm-5 {
  padding-right: 3rem !important;
}

.pb-sm-0 {
  padding-bottom: 0 !important;
}

.pb-sm-1 {
  padding-bottom: 0.25rem !important;
}

.pb-sm-2 {
  padding-bottom: 0.5rem !important;
}

.pb-sm-3 {
  padding-bottom: 1rem !important;
}

.pb-sm-4 {
  padding-bottom: 1.5rem !important;
}

.pb-sm-5 {
  padding-bottom: 3rem !important;
}

.ps-sm-0 {
  padding-left: 0 !important;
}

.ps-sm-1 {
  padding-left: 0.25rem !important;
}

.ps-sm-2 {
  padding-left: 0.5rem !important;
}

.ps-sm-3 {
  padding-left: 1rem !important;
}

.ps-sm-4 {
  padding-left: 1.5rem !important;
}

.ps-sm-5 {
  padding-left: 3rem !important;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.align-middle {
  vertical-align: middle !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}
.d-flex {
  display: flex !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}
.align-self-center {
  align-self: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-3half {
  font-size: calc(1.3rem + 0.5vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

/* #about h2 {
  position: relative;
  font-size: 28px;
  margin-bottom: 15px;
  padding-bottom: 15px;
} */
/* .about_container {
  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; */
/* padding-bottom: 0px; */
/* height: auto !important; */
/* } */
/* .about_img {
  display: block;
  max-width: 260px;
  height: auto;
} */

/* .about_title_wrapper {
  width: 100%;
} */
/* .aboutrow {
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
  padding-bottom: 0px;
  height: auto !important; */
/* display: flex;
  flex-direction: column-reverse; */
/* } */
/* .aboutcolumnleft {
  float: left;
  max-width: 20%;
  height: auto;
  padding-right: 40px;
}
.aboutcolumnright {
  padding-top: 20px;
  padding-left: 55px;
  float: right;
  max-width: 80%;
  height: auto;
} */
